export const translationJa = {
  language: '日本語',
  dashboard: {
    title: 'ダッシュボード'
  },
  headers: {
    titleAccount: '管理者'
  },
  pageTitle: {
    dashboard: 'ダッシュボード',
    callback: '折り返し電話',
    myDevices: '私のデバイス',
    devices: 'デバイス',
    request: 'リクエスト',
    requestsDetails: 'リクエストの詳細',
    deviceHistory: 'デバイス',
    users: 'ユーザー',
    userProfile: 'ユーザープロフィール',
    tenants: 'テナント',
    groups: 'グループ',
    historyDeviceDetails: '履歴デバイスの詳細',
    deviceDetails: 'デバイスの詳細',
    myGroup: '自分のグループ',
    deviceGroups: 'グループ/デバイス',
    statistics: '統計',
    personal: {
      title: '個人的',
      myDevice: '私のデバイス',
      search: {
        byCategory: 'カテゴリから探す',
        byStatus: 'ステータスから探す',
        minRemainingPrice: '最低残存価格',
        maxRemainingPrice: '最大残存価格',
        purchaseDateFrom: '購入日から',
        purchaseDateTo: '購入日まで',
        borrowDateFrom: '借りた日',
        borrowDateTo: '借入日',
        returnDateFrom: '返却日の開始日',
        returnDateTo: '返却日'
      },
      createNew: {
        titleButton: '新しく作る',
        titleModal: 'デバイスの作成'
      },
      exportFile: 'エクスポートファイル',
      table: {
        id: 'ID',
        deviceName: '装置名',
        assignee: '譲受人',
        assigneeName: '譲受人名',
        status: 'スターテス',
        category: 'カテゴリー',
        deviceType: 'デバイスタイプ',
        purchaseDate: '購入日',
        activationDate: 'アクティベーション日',
        warrantyPeriod: '保証期間',
        originalPrice: '元値',
        allotmentTime: '割り当て時間',
        remainingPrice: '残りの価格',
        borrowDate: '借用日',
        returnDate: '返却日',
        actions: '行動',
        invoiceNumber: '請求書番号',
        supplier: 'サプライヤー',
        managerNotes: 'マネージャーメモ',
        serialNumber: 'シリアルナンバー',
        deviceCode: 'デバイスコード',
        description: '説明'
      },
      upload: {
        textContent: 'ファイルをクリックまたはこの領域にドラッグしてアップロードします',
        hintUpload: '単一アップロードまたは一括アップロードのサポート。',
        typeUpload: '許可タイプ .jpg, .jpeg, .png, .gif.'
      },
      modalRequest: {
        titleModal: 'リクエストの作成',
        titleLabel: 'タイトル',
        device: 'デバイス',
        tracker: 'トラッカー',
        priority: '優先順位',
        startDate: '開始日',
        dueDate: '期日',
        decision: '決断'
      },
      modalDeleteDevice: {
        title: 'デバイスの削除',
        content: 'このデバイスを削除してもよろしいですか?'
      }
    },
    search: {
      byUserName: 'ユーザー名で検索',
      byTitleToName: 'タイトル / 譲受人名 / 説明 / 著者名 / デバイス名で検索',
      byDeviceNameToDeviceCodeFirst: 'デバイス名/譲受人名/シリアル番号/サプライヤー/説明/デバイスコードで検索',
      byDeviceNameToDeviceCodeSecond: 'デバイス名 / 譲受人名 / シリアル番号 / 請求書番号 / サプライヤー / 説明 / デバイス コードで検索',
      byUpdater: '更新者/譲受人/メモで検索',
      byTenant: 'テナント名/説明/サブドメインで検索',
      byGroups: 'グループ名・説明で検索',
      byTracker: 'トラッカーで検索',
      byPriority: '優先度から探す',
      byStatus: 'ステータスから探す',
      byStartDateForm: '開始日から',
      byStartDateTo: '開始日から終了日まで',
      byDueDateForm: '期限日から',
      byDueDateTo: '期限まで',
      byRole: '役割から探す',
      byAllowedDomains: '許可されたドメインで検索する'
    },
    management: {
      title: '管理',
      device: 'デバイス',
      requests: {
        description: '説明',
        back: '戻る',
        closeRequest: 'クローズリクエスト',
        updateRelated: '関連デバイスのアップデート',
        deleteRequest: '削除リクエスト',
        updateRequest: 'アップデート',
        createRequest: '作成',
        request: 'リクエスト',
        title: 'リクエスト',
        tableRequests: {
          title: 'タイトル',
          author: '著者',
          assignee: '譲受人',
          tracker: 'トラッカー',
          deviceName: '装置名',
          priority: '優先順位',
          status: 'スターテス',
          startDate: '開始日',
          dueDate: '期日',
          actions: 'アクション'
        }
      },
      deviceHistory: {
        title: 'デバイス履歴',
        table: {
          Updater: 'アップデーター',
          updated: '更新しました',
          status: 'スターテス',
          assignee: '譲受人',
          note: 'ノート',
          actions: '行動'
        }
      }
    }
  },
  userForm: {
    user: 'ユーザー',
    email: 'メールアドレス',
    role: '役割',
    action: 'アクション'
  },
  cancel: 'キャンセル',
  delete: '消去',
  update: 'アップデート',
  create: '作成する',
  back: '戻る',
  viewProfile: 'プロフィールを見る',
  signOut: 'サインアウト',
  month: '月',
  updateRole: 'ロールの更新',
  removeUser: 'ユーザーの削除',
  finish: '仕上がり',
  noData: 'データがありません。',
  datepickerPlaceholder: '日付を選択してください。',
  triggerDesc: 'クリックして降順に並べ替えます',
  triggerAsc: 'クリックして昇順に並べ替えます',
  cancelSort: 'クリックして並べ替えをキャンセルします',

  message: {
    requiredRule: 'この項目は必須です。',
    history: {
      updateSuccessfully: '履歴メモが更新されました。',
      error: 'エラー',
      handleError: 'エラーが発生しました。',
      note: 'ノート',
      create: '作成',
      update: 'アップデート'
    },
    deleteModalDevice: {
      title: 'デバイスの削除',
      content: 'このデバイスを削除してもよろしいですか'
    },
    deviceForm: {
      requiredOriginalPrice: {
        maximum: '元価格は10文字以下で入力してください。',
        activationPurchase: '有効日は購入日以降の日付を入力してください。',
        borrowPurchase: '借用日は購入日以降の日付を入力してください。',
        returnPurchase: '返却日は購入日以降の日付を入力してください。',
        returnBorrow: '返却日は借用日以降の日付を入力してください。'
      },
      maxMinValue: {
        lessThan: '未満である必要があります',
        greaterThan: 'より大きい必要があります'
      },
      requiredDeviceName: '氏名は250文字以下で入力してください。',
      requiredDeviceType: 'デバイスの種類は20文字以下で入力してください。'
    },
    login: {
      success: 'ログインされました。',
      error: 'メールにアクセス権限がありません。'
    },
    deleteForm: {
      success: 'デバイスを削除されました。'
    },
    closeRequestForm: {
      success: 'リクエストを閉じされました。',
      checkReturnDate: '返却日は借用日以降の日付を入力してください。'
    },
    contentDetails: {
      error: 'あなたはこのデータを表示する権限がありません'
    },
    deleteFormRequest: {
      success: '削除されました。',
      confirmDelete: 'このリクエストを削除してもよろしいですか?'
    },
    requestForm: {
      createSuccess: '作成されました。',
      updateSuccess: 'リクエストを更新されました。',
      checkDueDate: '期限日は開始日以降の日付を入力してください。',
      requestAndDevice: 'リクエストを閉じてデバイスを更新する'
    },
    deleteFormTenants: {
      success: 'テナントが正常に削除されました。',
      confirmDelete: 'このテナントを削除してもよろしいですか?'
    },
    detailsTenants: {
      name: '名前:',
      description: '説明：',
      table: {
        name: '名前',
        description: '説明',
        actions: '行動'
      }
    },
    tenantForm: {
      titleCreate: 'テナントの作成',
      titleUpdate: 'テナントの更新',
      titleDelete: 'テナントの削除',
      titleDetail: 'テナント詳細',
      createSuccess: '作成されました。',
      updateSuccess: 'テナントを更新されました。',
      name: '名前',
      selectUser: 'ユーザーの選択',
      placeholder: '選んでください',
      description: '説明'
    },
    deleteFormUser: {
      success: 'ユーザーが正常に削除されました。',
      confirm: 'このユーザーを削除してもよろしいですか?'
    },
    editRoleUser: {
      success: 'ユーザーが正常に更新されました。',
      roles: '役割'
    },
    apiService: 'セッションの有効期限が切れました。'
  },

  devices: {
    createDevice: 'デバイスの作成',
    updateDevice: 'デバイスを更新する',
    statusDevice: {
      available: '利用可能',
      broken: '壊れた',
      using: '使用中',
      warranty: '保証',
      preparing: '準備中',
      deleted: '削除済み'
    },

    deviceTypes: {
      company: '会社',
      personal: '個人的な'
    }
  },

  requests: {
    myRequest: '私の要求',
    authorName: '著者名',
    tracker: 'トラッカー',
    status: 'スターテス',
    dueDate: '期日',
    managerNotes: 'マネージャーメモ',
    deviceName: 'デバイス名',
    assigneeName: '譲受人名',
    priority: '優先',
    startDate: '開始日',
    decision: '決定',
    description: '説明'
  },
  common: {
    open: '開ける',
    inProgress: '進行中',
    approvedByManager: 'マネージャーによって承認',
    approvedByAdmin: '管理者によって承認',
    preparing: '準備中',
    rejected: '拒否された',
    closed: '閉じた',
    all: '全て'
  },

  tracker: {
    borrow: '借りる',
    buy: '買う',
    return: '戻る',
    warranty_claim: '保証請求'
  },

  priority: {
    low: '低い',
    normal: '正常',
    high: '高い',
    urgent: '緊急',
    immediate: '即時'
  },

  role: {
    user: 'ユーザー',
    manager: 'マネジメント',
    operator: 'オペレーター',
    admin: '管理者'
  },

  login: {
    loginTitle: 'Vareal IDでログイン',
    welcome: 'Devices System へようこそ。',
    titleSignIn: 'アカウントにサインインして冒険を始めましょう！'
  },
  label: {
    name: '名前',
    description: '説明',
    actions: 'アクション'
  },
  groups: {
    create: 'グループ作成',
    update: 'グループ更新',
    title: 'グループ',
    createSuccess: 'グループを正常に作成しました！',
    updateSuccess: 'グループを正常に更新しました！',
    selectManagerGroup: 'マネージャーグループ選択',
    selectUserGroup: 'ユーザーグループ選択',
    deleteSuccess: 'グループを正常に削除しました！',
    confirmDelete: 'このグループを削除してもよろしいですか？',
    titleDelete: 'グループ削除'
  },
  statistics: {
    device_type: 'デバイスタイプ',
    available: '利用可能',
    using: '使用中',
    broken: '壊れた',
    warranty: '保証',
    deleted: '削除済み',
    preparing: '準備中',
    total: '合計'
  }
}
